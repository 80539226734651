<template>
    <div class="row justify-content-center mt-5">
      <div :class="{'footer-login-home':type === 'home'}" v-if="!isMobile">
        <div class="left-footer">
          <p>{{ $t('base.new_footer_base') }}</p>
        </div>

        <div class="right-footer">
          <p>{{ $t('base.powered_by') }}</p>
          <a href="https://greenn.com.br/" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/icons/greenn_club.svg" alt="logogreenn">
          </a>
        </div>
      </div>
      <div :class="{'footer-login-home-mobile':type === 'home'}" v-else>
        <p>{{ $t('base.footer_base') }}</p>
        <a href="https://greenn.com.br/" target="_blank" rel="noopener noreferrer">
          <img v-if="themeDark" src="@/assets/greenn_club.png" alt="logogreenn">
          <img v-if="!themeDark" src="@/assets/greenn__club_0.png" alt="logogreenn">
        </a>
      </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    props:['type'],
    data() {
    return {
      client:{
        width: 0
      },
      themeDark:false
    };
  },
    computed: {
        isMobile() {
        return this.client.width <= 576;
        },
    },
    created(){
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    mounted(){
      this.getTheme();
      this.$root.$on("loadsettheme", () => {
        this.getTheme();
      });
    },
    methods: {
      getTheme(){
        const d = document;
        var theme = Cookies.get("themeClub");
        if (theme === undefined || theme === null) {
          this.themeDark = false
        } else {
          d.documentElement.getAttribute("data-theme");
          if (theme === "dark") {
            this.themeDark = true
          } else {
            this.themeDark = false
          }
        }
      },
      handleResize() {
          this.client.width = window.innerWidth;
      }, 
    }
}
</script>

<style lang="scss" scoped>
.right-footer {
  margin-right: 96px;
  display: flex;
  gap: 16px;
}

.left-footer {
  margin-left: 96px;
}

.footer-login-home {
  background: var(--menu-footer);
  position: fixed;
  display: flex;
  left: 0px;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 27px;
  padding-top: 27px;
}

.footer-login-home p{
  font-size: 10px;
  font-family: Montserrat;
  margin-right: 3px;
  color:var(--fontcolor);
  margin-bottom: 10px;
}

.footer-login-home span {
  font-weight: 600;
  color: var(--maincolor);
}

.footer-login-home img {
  width: 100px;
  margin-top: -1px;
}

.footer-login-home-mobile {
  position: absolute;
  display: flex;
}

.footer-login-home-mobile p{
  font-size: 10px;
  font-family: Montserrat;
  margin-right: 3px;
  color:var(--fontcolor);
}

.footer-login-home-mobile span {
  font-weight: 600;
  color: var(--maincolor);
}

.footer-login-home-mobile img {
  width: 100px;
  margin-top: -1px;
}
</style>
